<script>
import { ArrowUpIcon } from "vue-feather-icons";

import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import GeneralService from "@/MainServices/GeneralService.js";

/**
 * Page-job-sector component
 */
export default {
  data() {
    return {
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      letters: [],
      data: [],
      holder: [],
      originalData: [],
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
  },
  mounted() {
    this.getResults();
    this.cal_letters();
  },
  methods: {
    getResults(page = 1) {
      axios.get(`${this.apiUrl}/get-job-sectors`).then((response) => {
        this.data = response.data;
        this.holder = response.data;
      });
    },
    cal_letters() {
      for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
        this.letters.push(String.fromCharCode([i]));
      }
    },
    filterJobSector(item) {
      this.data = this.holder;
      this.data = this.data.filter((s) => s.job_function_name[0] == item);

      // if (this.data.length == 0) {
      //   this.data = "No record Found";
      //   console.log("ehhhhh", this.data.length);
      // }
    },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->

    <!--end container-->

    <!--Shape End-->

    <!-- <pagination :data="data">
      <span slot="prev-nav">&lt; Previous</span>
      <span slot="next-nav">Next &gt;</span>
    </pagination> -->

    <!-- Job List Start -->

    <section class="section" style="padding-bottom: 30px">
      <div class="container">
        <h4 class="simple-heading on_mobile_ch_h">JOBS BY SECTOR</h4>
        <div class="row bg-white p-4 m-0 by_ch_job_secter on_mobile_ch_h">
          <div class="col p-0" v-for="(item, index) in letters" :key="index">
            <span v-on:click="filterJobSector(item)">{{ item }}</span>
          </div>
          <a class="col p-0" href="/job-sectors"><span>Clear</span></a>
        </div>
        <div class="row">
          <div
            class="col-lg-2 col-md-3 col-6 mt-4 pt-2 by_ch_all_secter"
            v-for="(item, index) in data"
            :key="index"
          >
            <div
              class="card explore-feature border-0 rounded text-center bg-white"
              style="min-height: 170px; max-height: 170px"
            >
              <div class="card-body py-5">
                <ul
                  class="list-unstyled head mb-0"
                  style="display: flex; justify-content: flex-end"
                >
                  <li class="badge badge-success badge-pill">
                    {{ item.count }}{{ item.count > 100 ? "+" : "" }}
                  </li>
                </ul>
                <div class="icon shadow-lg d-inline-block">
                  <img
                    src="images/job/full.jpg"
                    alt=""
                    class="img-fluid rounded-top mx-auto"
                    style="height: 100%; width: 100%"
                  />
                </div>
                <div class="pt-6">
                  <h5 style="font-size: 12px; font-weight: bold">
                    <router-link
                      :to="
                        '/jobs-careers-pakistan/jobs-sector/' +
                        item.job_function_name
                      "
                      class="title text-dark"
                      >{{ item.job_function_name }}</router-link
                    >
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--end section-->
    <!-- Job List End -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
